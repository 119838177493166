<template>
  <div>
    <b-card
      title="รายชื่อสมาชิก"
      no-body
    >
      <b-card-body>
        <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="วันที่"
                label-for="mc-country"
              >
                <b-row>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.startdate"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                  <b-col md="6">
                    <flat-pickr
                      v-model="tableQuery.enddate"
                      class="form-control"
                      :config="{ enableTime: false,dateFormat: 'Y-m-d', time_24hr: true, minuteIncrement:1 }"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="fetchReport"
              >
                ดูรายงาน
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="clearSearch"
              >
                เคลียร์
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!-- </div> -->
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-table
        ref="reportTable"
        striped
        hover
        responsive
        class="position-relative"
        :items="statdata"
        :fields="fields"
      />
    </b-card>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BButton, BCardBody, BRow, BCol, BForm, BFormInput, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    // BFormSelect,
    // BPagination,
    // Cleave,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    // vSelect,
    flatPickr,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statdata: [],
      tableQuery: {
        page: 1,
        page_size: 100,
        startdate: moment().startOf('month').format('YYYY-MM-DD'),
        enddate: moment().endOf('month').format('YYYY-MM-DD'),
      },
      fields: [
        {
          key: 'date',
          label: 'วันที่',
          formatter: val => (moment(val).format('DD/MM/YYYY').indexOf('Invalid') >= 0 ? val : moment(val).format('DD/MM/YYYY')),
        },
        {
          key: 'member_new',
          label: 'สมัครใหม่',
          formatter: val => parseInt(`${val}`),
        },
        {
          key: 'member_new_deposit',
          label: 'สมัครใหม่เติมเงิน',
          formatter: val => parseInt(`${val}`),
        },
        {
          key: 'member_all_active',
          label: 'เข้าเล่น',
          formatter: val => parseInt(`${val}`),
        },
        {
          key: 'total_deposit',
          label: 'ฝากรวม',
          formatter: val => this.numberWithCommas(val),
          tdClass: 'td-currency',
        },
        {
          key: 'total_withdraw',
          label: 'ถอนรวม',
          formatter: val => this.numberWithCommas(val),
          tdClass: 'td-currency',
        },
        {
          key: 'total_promotion',
          label: 'จ่ายโปรรวม',
          formatter: val => this.numberWithCommas(val),
          tdClass: 'td-currency',
        },
        {
          key: 'total_winloss',
          label: 'ยอดวินลอส',
          formatter: val => this.numberWithCommas(val || '0.0'),
          tdClass: 'td-currency',
        },
        {
          key: 'total_wallet',
          label: 'ยอดเงินลูกค้า',
          formatter: val => this.numberWithCommas(val || '0.0'),
          tdClass: 'td-currency',
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
    // Set the initial number of items
    this.fetchReport()
  },
  methods: {
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    saveToExcel(dataToExports) {
      // import('@/libs/Export2Excel').then(excel => {
      //   let list = []
      //   if (dataToExports === 'new_members')
      //     list = this.new_members
      //   else if (dataToExports === 'walkin_members')
      //     list = this.walkin_members
      //   else if (dataToExports === 'active_members')
      //     list = this.active_members
      //   else if (dataToExports === 'firsttopup_members')
      //     list = this.firsttopup_members
      //   else if (dataToExports === 'comeback_members')
      //     list = this.comeback_members
      //   else if (dataToExports === 'inactive_members')
      //     list = this.inactive_members
      //   const headerTitle = ['เบอร์โทร', 'ยูสเซอร์', 'ชื่อ', 'นามสกุล', 'รหัสธนาคาร', 'เลขที่บัญชี', 'วันที่สมัคร', 'เข้าเล่นล่าสุด']
      //   const headerVal = ['phoneno', 'username', 'fname', 'lname', 'bank_code', 'bank_acc_no', 'created_at', 'updated_at']
      //   const data = this.formatJson(headerVal, list)
      //   excel.export_json_to_excel({
      //     header: headerTitle,
      //     data,
      //     filename: `${dataToExports}-${this.tableQuery.startdate}`,
      //     autoWidth: true,
      //     bookType: 'xlsx',
      //   })
      //   this.clearFields()
      // })
    },
    fetchReport(mode) {
      this.$http.post('report/webstats', { startdate: this.tableQuery.startdate, enddate: this.tableQuery.enddate }).then(({
        data: {
          success, stats,
        },
      }) => {
        const sumData = {
          date: 'รวม',
          member_new: 0,
          member_new_deposit: 0,
          member_all_active: 0,
          total_deposit: 0.0,
          total_withdraw: 0.0,
          total_promotion: 0.0,
          total_winloss: 0.0,
          total_wallet: 0.0,
        };
        (stats || []).forEach(element => {
          sumData.member_new += parseInt(element.member_new || '0')
          sumData.member_new_deposit += parseInt(element.member_new_deposit || '0')
          sumData.member_all_active += parseInt(element.member_all_active || '0')
          sumData.total_deposit += parseFloat(element.total_deposit || '0.0')
          sumData.total_withdraw += parseFloat(element.total_withdraw || '0.0')
          sumData.total_promotion += parseFloat(element.total_promotion || '0.0')
          sumData.total_winloss += parseFloat(element.total_winloss || '0.0')
          sumData.total_wallet += parseFloat(element.total_wallet || '0.0')
        })
        this.statdata = [...stats, sumData]
      })
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      // this.$refs.membertable.refresh()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.td-currency {
  text-align: right;
}
</style>
